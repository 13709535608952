import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import styled from "styled-components";

const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;

export const StyledButton = styled.button`
  font-size: 25px;
  font-family: "Caveat Brush";
  padding-top: 20px;
  border-radius: 10px;
  border: none;
  background-color: var(--accent-text-secondary);
  padding: 20px;
  color: var(--secondary-text);
  width: auto;
  cursor: pointer;
    :hover {
      box-shadow: 0px 6px 0px -2px #121212;
      -webkit-box-shadow: 0px 6px 0px -2px #121212;
      -moz-box-shadow: 0px 6px 0px -2px #121212;
  }
`;

export const Styled2Button = styled.button`
  font-size: 25px;
  font-family: "Caveat Brush";
  border-radius: 10px;
  border: none;
  background-color: var(--accent-text);
  padding: 20px;
  color: var(--secondary);
  width: auto;
  cursor: pointer;
  :hover {
    box-shadow: 0px 6px 0px -2px #7e7e7e;
    -webkit-box-shadow: 0px 6px 0px -2px #7e7e7e;
    -moz-box-shadow: 0px 6px 0px -2px #7e7e7e;
  }
`;

export const StyledRoundButton = styled.button`
  padding: 10px;
  border-radius: 100%;
  border: none;
  background-color: var(--secondary-text);
  padding: 20px;
  font-weight: bold;
  font-size: 20px;
  color: var(--primary-text);
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;

export const StyledLogo = styled.img`
  width: 200px;
  @media (min-width: 767px) {
    width: 700px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledImg = styled.img`
  width: 400px;
  position: absolute;

  @media (max-width: 800px) {
    display: none;
  }
  @media (min-width: 900px) {
    width: 300px;
  }
  @media (min-width: 1000px) {
    width: 470px;
    
  }
  transition: width 0.5s;
`;

export const Roadmap = styled.div`
  @media (max-width: 800px) {
    display: none;
  }
`;

export const Styled2Img = styled.img`
  width: 420px;
  position: absolute;
  

  @media (max-width: 800px) {
    display: none;
  }

  @media (min-width: 900px) {
    width: 250px;
    overflow: hidden;
  }
  @media (min-width: 1000px) {
    width: 420px;
    
  }
  transition: width 0.5s;
`;

export const CoolImg = styled.img`

  width: 200px;
  @media (max-width: 700px) {
    display: none;
  }
  @media (min-width: 900px) {
    width: 100%;
  }
  @media (min-width: 1000px) {
    width: 100%;
  }
  transition: width 0.5s;
`;

export const StyledLink = styled.a`
font-size: 25px;
font-family: "Caveat Brush";
color: var(--secondary);
width: auto;
cursor: pointer;
:hover {
  box-shadow: 0px 6px 0px -2px #3862ff;
  -webkit-box-shadow: 0px 6px 0px -2px #3862ff;
  -moz-box-shadow: 0px 6px 0px -2px #3862ff;
}
`;

export const Styled2Link = styled.a`
font-size: 25px;
font-family: "Caveat Brush";
color: var(--secondary);
width: auto;
cursor: pointer;
:hover {
  box-shadow: 0px 6px 0px -2px #f97316;
  -webkit-box-shadow: 0px 6px 0px -2px #f97316;
  -moz-box-shadow: 0px 6px 0px -2px #f97316;
  
}
`;

export const StyledP = styled.p`
font-size: 20px;
font-family: "Caveat Brush";
background-color: var(--secondary);
padding: 10px;
color: var(--secondary-text);
width: 35%;
text-align: center;
`;

function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(`Click "MINT ALOTTA" to mint your NFT.`);
  const [mintAmount, setMintAmount] = useState(1);
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });

  const claimNFTs = () => {
    let cost = CONFIG.WEI_COST;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * mintAmount);
    let totalGasLimit = String(gasLimit * mintAmount);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .mint(mintAmount)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          `WOW, the ${CONFIG.NFT_NAME} is yours! go visit Opensea.io to view it.`
        );
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const incrementMintAmount = () => {
    let newMintAmount = mintAmount + 1;
    if (newMintAmount > 10) {
      newMintAmount = 10;
    }
    setMintAmount(newMintAmount);
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  return (
    <s.Screen>
      <s.Container
        flex={1}
        ai={"center"}
        style={{  backgroundColor: "var(--primary)" }}
        image={CONFIG.SHOW_BACKGROUND ? "/config/images/bkgd2.png" : null}
      >
        <StyledLogo alt={"logo"} src={"/config/images/logov3.png"} />
        <s.SpacerSmall />
        <ResponsiveWrapper flex={1} style={{ padding: 24 }}>
          <s.Container flex={1} jc={"center"} ai={"center"} >
            <StyledImg alt={"example"} src={"/config/images/left.png"} />
          </s.Container>
          <s.SpacerLarge />
          <s.Container
            flex={2}
            jc={"center"}
            ai={"center"}
            style={{
              backgroundColor: "var(--accent)",
              padding: 25,
              borderRadius: 24,
              border: "4px solid var(--secondary)",
              boxShadow: "0px 5px 11px 2px rgba(0,0,0,0.7)",
            }}
          >
            <s.TextTitle
              style={{
                textAlign: "center",
                fontWeight: "bold",
                color: "var(--accent-text)",
              }}
            >
              <h1>{data.totalSupply} / {CONFIG.MAX_SUPPLY}</h1>
            </s.TextTitle>
            
            <s.TextDescription
              style={{
                textAlign: "center",
                color: "var(--accent-text-secondary)",
              }}
            >
              <StyledLink target={"_blank"} href={CONFIG.SCAN_LINK}>
                {truncate(CONFIG.CONTRACT_ADDRESS, 15)}
              </StyledLink>
            </s.TextDescription>
  
            {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (
              <>
                <s.TextTitle
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                  The sale has ended.
                </s.TextTitle>
                <s.TextDescription
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                  You can still find {CONFIG.NFT_NAME} on
                </s.TextDescription>
                <StyledLink target={"_blank"} href={CONFIG.MARKETPLACE_LINK}>
                  {CONFIG.MARKETPLACE}
                </StyledLink>
              </>
            ) : (
              <>
                <s.TextTitle
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                  1 {CONFIG.SYMBOL} costs {CONFIG.DISPLAY_COST}{" "}
                  {CONFIG.NETWORK.SYMBOL}.*
                </s.TextTitle>
                {blockchain.account === "" ||
                blockchain.smartContract === null ? (
                  <s.Container ai={"center"} jc={"center"}>
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                    >
                     <p>Connect your MetaMask to the {CONFIG.NETWORK.NAME} network!</p>
                    </s.TextDescription>
                    <s.SpacerSmall />
                    <StyledButton
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(connect());
                        getData();
                      }}
                    >
                      <h6>Connect Wallet</h6>
                    </StyledButton>
                    {blockchain.errorMsg !== "" ? (
                      <>
                        <s.SpacerSmall />
                        <s.TextDescription
                          style={{
                            textAlign: "center",
                            color: "var(--accent-text)",
                          }}
                        >
                          {blockchain.errorMsg}
                        </s.TextDescription>
                      </>
                    ) : null}
                  </s.Container>
                ) : (
                  <>
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                    >
                      {feedback}
                    </s.TextDescription>
                    <s.SpacerMedium />
                    <s.Container ai={"center"} jc={"center"} fd={"row"}>
                      <StyledRoundButton
                        style={{ lineHeight: 0.4 }}
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          decrementMintAmount();
                        }}
                      >
                        <h5>-</h5>
                      </StyledRoundButton>
                      <s.SpacerMedium />
                      <s.TextDescription
                        style={{
                          textAlign: "center",
                          color: "var(--accent-text)",
                        }}
                      >
                        <h3>{mintAmount}</h3>
                      </s.TextDescription>
                      <s.SpacerMedium />
                      <StyledRoundButton
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          incrementMintAmount();
                        }}
                      >
                        <h5>+</h5>
                      </StyledRoundButton>
                    </s.Container>
                    <s.SpacerSmall />
                    <s.Container ai={"center"} jc={"center"} fd={"row"}>
                      <StyledButton
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          claimNFTs();
                          getData();
                        }}
                      >
                        <h4>{claimingNft ? "ALOTTA MINTING" : "MINT ALOTTA"}</h4>
                      </StyledButton>
                      
                    </s.Container>
                    
                  </>
                )}
              </>
            )}
            
            <s.Container jc={"center"} ai={"center"} style={{paddingTop: 20}}>
          <s.TextDescription
            style={{
              textAlign: "center",
              color: "var(--primary-text)",
            }}
          >
            <p>*Excluding gas fees. - Gas limit is set to {CONFIG.GAS_LIMIT} for the contract to
            successfully mint your NFT. </p>
          </s.TextDescription>
        
        </s.Container>
          </s.Container>
          <s.SpacerLarge />
          <s.Container flex={1} jc={"center"} ai={"center"}>
            <StyledImg
              alt={"example"}
              src={"/config/images/side.png"}
            />
          </s.Container>
          
        </ResponsiveWrapper>
      </s.Container>





      <ResponsiveWrapper  flex={1} style={{ padding: 5 }}>
          <s.SpacerLarge />
          <s.Container
            flex={2}
            jc={"center"}
            ai={"right"}
          > 
          <s.TextDescription
          style={{
            color: "var(--secondary-text)",
            textAlign: "center"
          }}
        >
          <h1>ALOTTA NFTS RARITY TRAITS</h1>
        </s.TextDescription>
      
      
          </s.Container>
          <s.SpacerLarge />
        </ResponsiveWrapper>


        <ResponsiveWrapper flex={1} style={{ padding: 24 }}>

        <s.SpacerLarge />
          <s.Container
            flex={2}
            jc={"center"}
            ai={"center"}
            style={{
              backgroundColor: "var(--secondary-text)",
              borderRadius: 24,
              boxShadow: "0px 5px 11px 2px rgba(0,0,0,0.7)",
            }}
          >
            <s.TextTitle
              style={{
                textAlign: "center",
                fontWeight: "bold",
                color: "var(--secondary)",
              }}
              
            >
              <s.SpacerSmall />
          
            </s.TextTitle>
            <s.TextTitle
              style={{
                textAlign: "center",
                color: "var(--grey)",
              }}
            >
              <code>Backgrounds</code>
            </s.TextTitle>
            <s.TextTitle
              style={{
                textAlign: "center",
                color: "var(--accent-text-secondary)",
              }}
            >
              <h3>23</h3>
            </s.TextTitle>
            <s.SpacerSmall />
          </s.Container>

          <s.SpacerLarge />
          <s.Container
            flex={2}
            jc={"center"}
            ai={"center"}
            style={{
              backgroundColor: "var(--secondary-text)",
              borderRadius: 24,
              boxShadow: "0px 5px 11px 2px rgba(0,0,0,0.7)",
            }}
          >
            <s.TextTitle
              style={{
                textAlign: "center",
                fontWeight: "bold",
                color: "var(--secondary)",
              }}
              
            >
              <s.SpacerSmall />
            
            </s.TextTitle>
            <s.TextTitle
              style={{
                textAlign: "center",
                color: "var(--grey)",
              }}
            >
              <code>Alottas</code>
            </s.TextTitle>
            <s.TextTitle
              style={{
                textAlign: "center",
                color: "var(--accent-text-secondary)",
              }}
            >
              <h3>12</h3>
            </s.TextTitle>
            <s.SpacerSmall />
          </s.Container>

        

          <s.SpacerLarge />
          <s.Container
            flex={2}
            jc={"center"}
            ai={"center"}
            style={{
              backgroundColor: "var(--secondary-text)",
              borderRadius: 24,
              boxShadow: "0px 5px 11px 2px rgba(0,0,0,0.7)",
            }}
          >
            <s.TextTitle
              style={{
                textAlign: "center",
                fontWeight: "bold",
                color: "var(--secondary)",
              }}
              
            >
              <s.SpacerSmall />
           
            </s.TextTitle>
            <s.TextTitle
              style={{
                textAlign: "center",
                color: "var(--grey)",
              }}
            >
              <code>Accessories</code>
            </s.TextTitle>
            <s.TextTitle
              style={{
                textAlign: "center",
                color: "var(--accent-text-secondary)",
              }}
            >
              <h3>6</h3>
            </s.TextTitle>
            <s.SpacerSmall />
          </s.Container>
          <s.SpacerLarge />
          <s.Container
            flex={2}
            jc={"center"}
            ai={"center"}
            style={{
              backgroundColor: "var(--secondary-text)",
              borderRadius: 24,
              boxShadow: "0px 5px 11px 2px rgba(0,0,0,0.7)",
            }}
          >
            <s.TextTitle
              style={{
                textAlign: "center",
                fontWeight: "bold",
                color: "var(--secondary)",
              }}
              
            >
              <s.SpacerSmall />
          
            </s.TextTitle>
            <s.TextTitle
              style={{
                textAlign: "center",
                color: "var(--grey)",
              }}
            >
              <code>PowerUps</code>
            </s.TextTitle>
            <s.TextTitle
              style={{
                textAlign: "center",
                color: "var(--accent-text-secondary)",
              }}
            >
              <h3>10</h3>
            </s.TextTitle>
            <s.SpacerSmall />
          </s.Container>
    

          <s.SpacerLarge />
          <s.Container
            flex={2}
            jc={"center"}
            ai={"center"}
            style={{
              backgroundColor: "var(--secondary-text)",
              borderRadius: 24,
              boxShadow: "0px 5px 11px 2px rgba(0,0,0,0.7)",
            }}
          >
            <s.TextTitle
              style={{
                textAlign: "center",
                fontWeight: "bold",
                color: "var(--secondary)",
              }}
              
            >
              <s.SpacerSmall />
            
            </s.TextTitle>
            <s.TextTitle
              style={{
                textAlign: "center",
                color: "var(--grey)",
              }}
            >
              <code>Mouths</code>
            </s.TextTitle>
            <s.TextTitle
              style={{
                textAlign: "center",
                color: "var(--accent-text-secondary)",
              }}
            >
              <h3>10</h3>
            </s.TextTitle>
            <s.SpacerSmall />
          </s.Container>
         
          <s.SpacerLarge />
        </ResponsiveWrapper>

        
        <s.Container flex={1} jc={"center"} ai={"center"}>
            <CoolImg alt={"example"} src={"/config/images/bkgd-s.png"} />
          </s.Container>



          <ResponsiveWrapper  flex={1} style={{ padding: 5 }}>
          <s.SpacerLarge />
          <s.Container
            flex={2}
            jc={"center"}
            ai={"right"}
          > 
          <s.TextDescription
          style={{
            color: "var(--secondary-text)",
            textAlign: "center"
          }}
        >
          <h1>FREQUENTLY ASKED QUESTIONS</h1>
        </s.TextDescription>
          </s.Container>
          <s.SpacerLarge />
        </ResponsiveWrapper>

        <ResponsiveWrapper flex={1} style={{ padding: 24 }} >
        <s.SpacerLarge />
          <s.Container
            flex={2}
            jc={"center"}
            ai={"center"}
            style={{
              backgroundColor: "var(--secondary-text)",
              borderRadius: 24,
              boxShadow: "0px 5px 11px 2px rgba(0,0,0,0.7)",
            }}
          >
            <s.TextTitle
              style={{
                textAlign: "center",
                fontWeight: "bold",
                color: "var(--secondary)",
              }}
              
            >
              <s.SpacerMedium />
             <p>What are Alotta NFTs?</p>
            </s.TextTitle>
            <s.TextTitle
              style={{
                textAlign: "center",
                color: "var(--primary)",
                fontSize: "15px",
                textAlign: "justify",
                paddingTop: "10px",
                paddingLeft: "30px",
                paddingRight: "30px",
                paddingBottom: "20px",
              }}
            >
              <p>Alotta NFTs are a limited collection of 1337 NFTs created by Hand Drawn elements that are combined using an Auto-Generated Art Generator.</p>
            </s.TextTitle>
          </s.Container>

          <s.SpacerLarge />
          <s.Container
            flex={2}
            jc={"center"}
            ai={"center"}
            style={{
              backgroundColor: "var(--secondary-text)",
              borderRadius: 24,
              boxShadow: "0px 5px 11px 2px rgba(0,0,0,0.7)",
            }}
          >
            <s.TextTitle
              style={{
                textAlign: "center",
                fontWeight: "bold",
                color: "var(--secondary)",
              }}
              
            >
              <s.SpacerMedium />
             <p>Which Alotta will I get?</p>
            </s.TextTitle>
            <s.TextTitle
              style={{
                textAlign: "center",
                color: "var(--primary)",
                fontSize: "15px",
                textAlign: "justify",
                paddingTop: "10px",
                paddingLeft: "30px",
                paddingRight: "30px",
                paddingBottom: "20px",
              }}
            >
              <p>The fun aspect of the Alotta NFTs Minting Dapp is you will never know which Alotta you will get until you mint it and view it on Opensea!</p>
            </s.TextTitle>
          </s.Container>
          <s.SpacerLarge />

          <s.Container
            flex={2}
            jc={"center"}
            ai={"center"}
            style={{
              backgroundColor: "var(--secondary-text)",
              borderRadius: 24,
              boxShadow: "0px 5px 11px 2px rgba(0,0,0,0.7)",
            }}
          >
            <s.TextTitle
              style={{
                textAlign: "center",
                fontWeight: "bold",
                color: "var(--secondary)",
              }}
              
            >
              <s.SpacerMedium />
             <p>How do I mint an Alotta NFT?</p>
            </s.TextTitle>
            <s.TextTitle
              style={{
                textAlign: "center",
                color: "var(--primary)",
                fontSize: "15px",
                textAlign: "justify",
                paddingTop: "10px",
                paddingLeft: "30px",
                paddingRight: "30px",
                paddingBottom: "20px",
              }}
            >
              <p>- Connect your wallet above using MetaMask.</p>
              <p>- Enter amount to mint. (Max: 10 per TX - 0.03Ξ each)</p>
              <p>- Click "MINT ALOTTA" (wait for TX to finish.)</p>
            </s.TextTitle>
          </s.Container>
          <s.SpacerLarge />
          
        </ResponsiveWrapper>
    
      

          <ResponsiveWrapper style={{padding: "100px", background: "linear-gradient(#121212, #191919)"}}>
        <s.SpacerLarge />
        <s.Container
            flex={3}
            jc={"center"}
            ai={"center"}
          > 
        
                    <s.TextDescription
          style={{
            color: "var(--secondary-text)",
            textAlign: "center",
            padding: "20px"
          }}
        >
          <h1>VIEW THE ENTIRE ALOTTA NFTs COLLECTION</h1>
        </s.TextDescription>
      
        <s.SpacerLarge />
        <s.Container ai={"center"} jc={"center"} fd={"row"} style={{
                    paddingBottom: "70px",
                  }}>
        <a href="https://opensea.io/collection/alottanfts" target="_blank">
          <Styled2Button>
                        <h4>OPENSEA</h4>
                      </Styled2Button>
                      </a>
            
                    </s.Container>
                    <ResponsiveWrapper style={{
                    paddingTop: "30px",
                    paddingLeft: "30px",
                    paddingRight: "30px",
                  }}>
                   
                    <s.Container ai={"center"} jc={"center"} fd={"row"} >
                            <div>
                    <a>
                      <img src="/config/images/logo-white.png" alt="" width="200px" />
                    </a>
                    <s.TextTitle
                    style={{
                      textAlign: "center",
                      color: "var(--accent)",
                      fontSize: "15px",
                      textAlign: "justify",
                    }}
                  >
                    <p>©2022 All rights reserved.</p>
                  </s.TextTitle>
                  </div>
                  </s.Container>
                  <ResponsiveWrapper
                  style={{
                    paddingTop: "20px",
                    paddingLeft: "30px",
                    paddingRight: "30px",
                  }}>
                  <s.Container flex={1} ai={"center"} jc={"center"} fd={"container"}>
                  <div>
                    <a href="https://twitter.com/money_alotta">
                    <Styled2Link>
                      <img src="/config/images/twitter-white.png" alt="" />
                      </Styled2Link>
                    </a>
                  </div>
                  
                  </s.Container>
                 
                  <s.Container flex={1} ai={"center"} jc={"center"} fd={"container"}>
                  <div>
                    <a href="https://opensea.io/collection/alottanfts">
                      <Styled2Link>
                      <img src="/config/images/opensea-white.png" alt="" />
                      </Styled2Link>
                    </a>
                  </div>
                  </s.Container>
                  
                  </ResponsiveWrapper>
                  </ResponsiveWrapper>
        
          </s.Container>
          <s.Container flex={1}>
            <Styled2Img 
              alt={"ALOTTA NFTS nft"}
              src={"/config/images/footer.png"}
            />
          </s.Container>
        </ResponsiveWrapper>

    </s.Screen>

  );
}



export default App;